/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
// Index .less, any imports here will be included in the final css build

@import './variables.less';
@import '~bootstrap/less/bootstrap.less';
@import './fonts.less';
@import './cosmo/bootswatch.less';

html,
body {
  font-size: @font-size-base;
  line-height: @line-height-base;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  flex: 0 1 auto;
}

#app {
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: column;
}
